<template>
  <div class="activities-container">
    <div class="activities-container-main">
      <div class="activities-bar">
        <span>{{ $t('navbar.sysActivities') }}</span>
        <SearchFilter
          ref="searchFilter"
          :filter-options.sync="filterOptions"
          :params="params"
          :total-page="totalPage"
          :delete-dialog-visible.sync="deleteDialogVisible"
          @update="handleFilter"
        />
      </div>
      <div class="activities-list">
        <el-table
          v-loading="listLoading"
          stripe
          :data="tableData"
        >
          <el-table-column
            v-for="(item, index) in field"
            :key="index"
            align="center"
            :label="$t(`activities.${item.label}`)"
            :prop="item.label"
            :width="item.width"
          />
          <template slot="empty">
            <el-empty :description="$t('general.NoData')" />
          </template>
        </el-table>
      </div>
    </div>
    <!-- pagination -->
    <div class="pagination-container">
      <el-pagination
        :background="true"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="pageLimit"
        :total="totalPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import SearchFilter from './components/SearchFilter.vue'
import { getActivities } from '@/api/activities'
import { UTCtoLocalTime } from '@/utils/handleTime'

const params = () => ({
  page: 1,
  page_limit: 20
})

export default {
  name: 'SysActivities',
  components: {
    SearchFilter
  },
  data () {
    return {
      listLoading: false,
      params: params(),
      filterOptions: {
        organization_id: null,
        user_name: null,
        action_type: [],
        dateRange: []
      },
      field: [
        {
          label: 'organization_name',
          width: 250
        },
        {
          label: 'user_name',
          width: 150
        },
        {
          label: 'action_type',
          width: 150
        },
        {
          label: 'details'
        },
        {
          label: 'act_at',
          width: 180
        }
      ],
      tableData: [],
      allOptions: [],
      deleteDialogVisible: false,
      currentPage: 1,
      pageLimit: 20,
      totalPage: 0
      
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    UTCtoLocalTime(time, format) {
      return UTCtoLocalTime(time, format)
    },
    loadData() {
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true

      try {
        const res = await getActivities(this.params)
        this.totalPage = res.data.page.total
        this.tableData = res.data.activities_list.map((item) => {
          const newObj = {
            ...item,
            action_type: this.$t(`activities.${item.action_type}`),
            act_at: UTCtoLocalTime(item.act_at, 'YYYY-MM-DD HH:mm:ss')
          }
          return newObj
        })
      } catch (error) {
        console.error(error)
      }
      this.listLoading = false
    },
    handleFilter() {
      this.params = params()
      if (this.filterOptions.user_name) this.params.search = this.filterOptions.user_name
      if (this.filterOptions.organization_id) this.params.organization_id = this.filterOptions.organization_id
      if (this.filterOptions.action_type.length > 0) this.params.action = this.filterOptions.action_type.toString()
      if (this.filterOptions.dateRange.length > 0) {
        this.params.from_date = this.filterOptions.dateRange[0]
        this.params.to_date = this.filterOptions.dateRange[1]
      }
      this.fetchData()
    },
    // for pagination
    handleSizeChange(val) {
      this.params.page_limit = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.activities-container {
  .activities-container-main {
    position: relative;
    flex-direction: column;
    .el-table .el-button {
      border: none;
      background-color: transparent;
    }
  }
}

.activities-bar {
  display:flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* pagination */
.pagination-container {
  @include justifyCenter;
  .el-pagination {
    margin-top: 10px;
    background-color: #f3f2f1;
  }
}
</style>
